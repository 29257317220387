<template>
    <div v-loading="Boxloading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>基础设置</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/poster' }">海报设置</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" label-width="120px" size="mini">
                <el-form-item label="模板名称：" label-position="left">
                    <el-input v-model="form.name" style="width:300px"></el-input>
                </el-form-item>
                <el-form-item label="模板：" label-position="left">
                    <select-images @onSelect="onSelect" @onDelete="onDelete" :image="image"></select-images>
                </el-form-item>

                <el-form-item label="跳转类型：" label-position="left" class="input">
                    <el-select style="width: 200px" v-model="form.type" placeholder="请选择" @change="selectType">
                        <el-option label="课程" :value="1"></el-option>
                        <el-option label="课程计划" :value="2"></el-option>
                    </el-select>
                </el-form-item>


                <el-form-item label="状态：" label-position="left" class="input">
                    <el-switch v-model="form.status" @change="setStatus">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
                </el-form-item>

                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <el-button style="margin-left: 10px" size="small" @click="$router.go(-1)">取消</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import SelectImages from "../../../components/selectImages/index";
import { config } from "@/js/config";
export default {
    name: "add",
    components: { SelectImages },
    data() {
        return {
            imageUrl: config.imageUrl,
            Boxloading: false,
            statusTxt: '上架',
            loading: false,
            image: '',
            form: {
                type: 1,
                status: true,
                pic: '',
                name: '',
            },
            options: [
                {
                    value: 0,
                    label: '仅展示图片'
                },
                {
                    value: 1,
                    label: '跳转页面'
                },
            ],

        }
    },
    methods: {
        ...mapActions('poster', ['addTemplate', 'getTemplate']),
        uploadSuccess(path) {
            this.form.pic = path
        },
        async onSubmit() {
            let _this = this
            this.loading = true
            let form = JSON.parse(JSON.stringify(this.form))
            form.status = form.status ? 1 : 0
            await this.addTemplate(form).then(res => {
                if (res.ret == 0) {
                    _this.$message.success('新增成功！')
                    _this.$router.push('/poster')
                }
            })
            this.loading = false
        },
        setStatus(status) {
            console.log(status)
            if (this.form.status) {
                this.statusTxt = '上架'
            } else {
                this.statusTxt = '下架'
            }
            this.form.status = status
        },
        selectType(val) {
            this.form.type = val
        },
        onSelect(image) {
            this.image = imageUrl + image.path
            this.form.pic = image.path
        },
        onDelete() {
            this.image = ''
        },
        async initData() {
            this.Boxloading = true
            const { data } = await this.getTemplate(this.$route.params.id)
            console.log(data);
            data.status = data.status == 1 ? true : false
            this.image = this.imageUrl + data.pic
            this.form = data
            this.Boxloading = false
        },
    },
    mounted() {
        this.initData()
    },
}
</script>

<style scoped>

</style>
